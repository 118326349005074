
<template>
  <!-- <n-layout-header bordered>
    <SiteHeader />
  </n-layout-header> -->

  <n-layout has-sider position="absolute">
    <SiteLeftMenu/>
    <n-layout
      :content-style="this.isMobile ? 'padding: 8px;' : 'padding: 64px;'"
      :native-scrollbar="false"
    >
      <n-space vertical>
        <!-- <pre>
          {{ currentMenuData }}
          {{ currentLessonJson[currentMenuData.data.path] }}
        </pre> -->
        <doc ref="docRef" :adminView="true"/>
        <br>
        <!-- <n-space> -->
          <!-- <n-button style="width: 100%" type="primary" @click="addContent" dashed>+ teksto bloką</n-button> -->
          <!-- <n-button style="width: 100%" type="primary" @click="addQuestion" dashed>+ klausimo bloką</n-button> -->
          <!-- <n-button style="width: 100%" type="primary" @click="addComplex" dashed>+ objekto bloką</n-button> -->
        <!-- </n-space> -->
        <!-- <n-button style="width: 100%" type="primary" @click="removeBlock" dashed>-</n-button> -->
        <br>
        <n-switch style="width: 100%" @click="updateDocLastUpdateDate = !updateDocLastUpdateDate">
          <template #checked>Informuoti vartotojus apie atnaujinimą</template>
          <template #unchecked>Neinformuoti vartotojų apie atnaujinimą</template>
        </n-switch>
        <n-switch style="width: 100%" :value="aggreeToTermsCheckboxValue" @click="updateAgreeToTermsCheckbox">
          <template #checked>Įpareigoti vartotojus sutikti su sąlygomis</template>
          <template #unchecked>Neįpareigoti vartotojų sutikti su sąlygomis</template>
        </n-switch>
        <br>
        <n-button style="width: 100%" type="primary" @click="saveLesson" :loading="isSaving" :disabled="isSaving">Išsaugoti</n-button>
        <br><br><br>
      </n-space>
    </n-layout>
  </n-layout>

</template>
<script>

// import SiteHeader from '@/components/SiteHeader.vue'
import SiteLeftMenu from '@/components/SiteLeftMenu.vue'
import { useIsMobile } from '@/utils/composables'
import doc from '@/pages/docs/doc'
import { useStore } from 'vuex'
import { ref, computed } from 'vue'

import { useMessage } from 'naive-ui'

const isMobileRef = useIsMobile()

export default {
  name: 'root',
  components: {
    // SiteHeader,
    SiteLeftMenu,
    doc,
  },
  setup() {
    const store = useStore()
    const message = useMessage()
    const currentMenuData = computed(() => { return store.getters['menu/getCurrentMenuData'] })
    const currentLessonJsonRef = computed(() => { return store.getters['lessons/getLessonData'] })
    const aggreeToTermsCheckboxValue = computed(() => {
      if (currentLessonJsonRef.value[currentMenuData.value.data.path]) {
        return currentLessonJsonRef.value[currentMenuData.value.data.path].agreeToTermsCheckbox
      } else {
        return false
      }
    })
    const docRef = ref(null)
    return {
      docRef,
      store,
      message,
      currentMenuData,
      currentLessonJson: currentLessonJsonRef,
      aggreeToTermsCheckboxValue,
      isMobile: isMobileRef,
      updateDocLastUpdateDate: ref(false),
      isSaving: ref(false),
    }
  },
  methods: {
    updateAgreeToTermsCheckbox() {
      this.currentLessonJson[this.currentMenuData.data.path].agreeToTermsCheckbox = !this.currentLessonJson[this.currentMenuData.data.path].agreeToTermsCheckbox
      console.log(this.currentLessonJson[this.currentMenuData.data.path].agreeToTermsCheckbox)
    },
    saveLesson() {
      const doc = this.currentLessonJson[this.currentMenuData.data.path]
      console.log(doc)
      this.isSaving = true
      this.store.dispatch('lessons/putLesson', { 'doc': doc, 'updateDocLastUpdateDate': this.updateDocLastUpdateDate }).then(resp => {
        if (resp.status == 200) {
          this.message.success('Išsaugota')
          console.log('lesson pushed to db', resp.data)
        } else {
          this.message.warning(resp.status)
        }
        this.currentLessonJson[this.currentMenuData.data.path] = this.docRef.currentContent
        this.isSaving = false
      })
    }
  }
}
</script>
